import './hero-teaser.scss';
import Swiper from 'swiper';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper/modules';

class HeroTeaser {
    constructor (element) {
        this.$slider = element;
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        this.$slider = new Swiper(this.$slider, {
            modules: [A11y, Navigation, Pagination, Keyboard],
            a11y: true,
            lazyPreloadPrevNext: true,
            keyboard: true,
            slidesPerView: 'auto',
            speed: 500,
            allowTouchMove: true,
            navigation: {
                nextEl: '.hero-teaser__arrow--next',
                prevEl: '.hero-teaser__arrow--prev'
            },
            breakpoints: {
                768: {
                    slidesPerView: 1
                }
            },
            pagination: {
                el: '.hero-teaser__pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' <span class="separator"></span> ' + '<span class="' + totalClass + '"></span>';
                }
            }
        });
    }
}

export { HeroTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-hero-teaser="slider"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new HeroTeaser($root);
                $rootAPI.initialize();
            });
        }
    }
});
